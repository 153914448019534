<template>
  <div v-if="getSettingstore" class="mevcut-container">
    <div class="bg-white p-3 my-10 min-h-[50vh] border rounded-lg">
      <div class="mb-10 text-xl uppercase">{{ t(keySettings) }}</div>
      <div style="line-height: 2rem" v-html="getSettingstore"></div>
    </div>
  </div>
  <div
    v-else
    class="flex flex-col min-h-[50vh] items-center justify-center bg-white"
  >
    <v-icon
      icon="mdi mdi-alert-circle-outline"
      :size="48"
      class="text-red-500 mb-5"
    />
    <div class="text-lg mb-3 text-black">
      {{ t('error_404') }}
    </div>
  </div>
</template>

<script setup lang="ts">
const { getSettingsByKey } = useStoresSettings()
const { url } = useDomainHost()
const { t } = useI18n()
const route = useRoute()
const { store } = useDomainState()

const keySettings = computed(() => {
  return route.params.id.toString()
})
const getSettingstore = computed(() => {
  return getSettingsByKey(keySettings.value)
})
useServerSeoMeta(
  useOgMeta(
    url + `/about/${keySettings.value}`,
    t('terms_of_use'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t(keySettings.value),
  link: [useSeoCanonical(url + `/about/${keySettings.value}`)],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, store.value?.name, url),
      useSeoBreadcrumbItem(
        2,
        t('terms_of_use'),
        url + `/about/${keySettings.value}`
      )
    ])
  ]
})
</script>
